@font-face {
  font-family: 'DomaineDispNar-Medium';
  src: url('./assets/fonts/DomaineDispNar-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'DomaineDispNar-Regular';
  src: url('./assets/fonts/DomaineDispNar-Regular.otf') format('opentype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: 'DomaineDispNar-Medium', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-size: 1.2rem;
  font-family: 'DomaineDispNar-Regular', sans-serif;
}

h1 {
  font-family: 'DomaineDispNar-Medium', sans-serif;
  font-size: 3rem !important;
  font-weight: normal;
}

h2 {
  font-family: 'DomaineDispNar-Medium', sans-serif;
  font-size: 2rem !important;
  font-weight: normal;
}

h3 {
  font-family: 'DomaineDispNar-Medium', sans-serif;
  font-size: 1.5rem !important;
  font-weight: normal;
}

h4 {
  font-family: 'DomaineDispNar-Medium', sans-serif;
  font-size: 1.4rem !important;
  font-weight: normal;
}

h5 {
  font-family: 'DomaineDispNar-Medium', sans-serif;
  font-size: 1.3rem !important;
  font-weight: bold;
}

a {
  font-family: 'DomaineDispNar-Medium', sans-serif;
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none;
  // color: #d96a14;
  color: #3b71ca;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.sticky {
  position: fixed;
  top: 0;
}

.blockspam {
  display: none;
}

.css-9mgopn-MuiDivider-root {
  border-color: rgba(0, 0, 0, 0.4) !important;
}

.fa-1x {
  margin-right: 5px;
}

@media screen and (max-width: 991px) {
  .top_logo {
    display: block;
    position: absolute;
    width: 100%;
  }
  
  .responsive_logo {
    display: none;
  }

  .hero_text {
    font-size: 3rem !important;
  }

  video {
    margin-top: 0px !important;
  }

  .navi_wrapper .top img {
    width: 50%;
  }

  .hamburger {
    display: block;
  }

  .navigation {
    padding: 0rem 0rem;
  }

  .nav-bar ul {
    z-index: 1;
    display: none;
    position: absolute;
    top: 100px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 77px);
    background-color: #2b2929;
  }

  .nav-bar li {
    margin: 0;
  }

  .nav-bar li a {
    color: #f4f6fc;
    width: 100%;
    padding: 1.5rem 0;
  }

  .nav-bar li:hover {
    background-color: #eee;
  }

  .nav-bar.expanded ul {
    display: block;
  }
}

.items_wrapper {
  text-align: left;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.case_list {
  list-style: none;
  padding: 0;
}

.case_list li{
  margin-bottom: 2rem;
}

.case_list p span {
  text-decoration: underline;
}

.case_list span:hover {
  color: #d96a14;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.about img {
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.post_show a {
  text-decoration: underline;
}

.nav-bar img {
  width: 10rem;
}

.nav-bar img {
  padding-top: 15px;
  padding-bottom: 15px;
}

.nav-link span {
  font-family: 'DomaineDispNar-Regular', sans-serif;;
}

.navbar-fixed-top svg {
  color: black;
}

.blog_entry {
  padding-top: 15px;
  padding-bottom: 5%;
}

.post_show h3 {
  padding-top: 3%;
  padding-bottom: 3%;
}

.post_show p {
  padding-bottom: 5%;
}

.hero_wrapper button {
  font-size: 1rem;
}

nav {
  background-color: transparent !important;
  box-shadow: none !important;
}

.navigation-bar {
  justify-content: space-between;
}

.nav-link {
  display: initial !important;
}

.nav-link button {
  font-size: 1.2rem;
  color: black;
  text-transform: none;
  font-family: 'DomaineDispNar-Regular', sans-serif;
}

.home-carousel {
  height: 60vh;
}

.css-1f8sh1y {
  height: 60vh !important;
}

.home-carousel img {
  width:100%;
  height: 60vh;
  object-fit: cover;
}

.navbar-fixed-top.scrolled {
  background-color: #fff !important;
  transition: background-color 200ms linear;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important;
}

.navbar-fixed-top.scrolled .nav-link {
  color:#555;
}

.home-container .paragraph {
  padding-left: 10%;
  padding-right: 10%;
  text-align: left;
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-container-1 {
  justify-content: space-between;
  padding-top: 10%;
  padding-bottom: 10%;
}

.home-container-1 img {
  float: right;
  height: 60vh;
}

.home-container-2 {
  justify-content: space-between;
  padding-top: 5%;
  padding-bottom: 10%;
}

.home-container-2 img {
  float: left;
  height: 75vh;
}

.custom-shape-divider-top-1711507572 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1711507572 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-top-1711507572 .shape-fill {
  fill: #2B2929;
}

@media screen {
  @media (max-width: 990px) {
    .items_wrapper {
      padding-left: 10%;
      padding-right: 10%;
    }

    .sticky {
      position: initial;
      top: initial;
    }

    .items_wrapper {
      margin-top: 0px !important;
    }
    
    .hero_wrapper {
      margin-top: 0px !important;
    }
  }

  @media (min-width: 991px) {
    .items_wrapper {
      padding-left: 10%;
      padding-right: 10%;
    }
  }
    
  @media (max-width: 990px) {
    .nav-bar li {
      font-size: 0.8rem;
    }
  }

  @media screen and (min-width: 281px) {
    .navi_wrapper .top_logo img {
      padding-top: 15px;
    }
  }


  @media screen and (max-width: 500px) {
    .navi_wrapper .top_logo img {
      width: 180px;
      float: left;
      padding-top: 25px;
      padding-left: 10px;
    }

    .hero_text {
      top: 40% !important;
      font-size: 2rem !important;
    }
  }

  @media screen and (max-width: 700px) {
    .card_wrapper {
      flex-direction: column !important;
    }
    .card {
      width: 100% !important;
    }
    .card .text img {
      width: 35vw !important;
    }
  }

  @media screen and (max-width: 991px) {
    .gallery_wrapper .col-md-3 {
        width: 100%;
      }
    }
}

@media screen and (max-width: 1000px) {
  // .works_list_grid {
  //   display: block !important;
  // }

  .works_list_grid {
    padding: 0px !important;
  }

  .grid_element {
    padding-left: 0px;
    padding-right: 0px;
  }

  .grid_element iframe {
    height: 45vw !important;
  }
}
.gallery_main .items_wrapper {
  margin-top: 0;
}

.gallery_main h1 {
  margin-bottom: 15px;
}

@layer base.app {
  .gallery_section {
    display: grid;
    justify-content: center;
    padding-inline: var(--size-1);
    gap: var(--size-7);
  }
}

@layer components.gallery {
  .imageGallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-inline-size: var(--max-inline);
    gap: calc(var(--border-size-2) * 2);

    & img {
      aspect-ratio: var(--ratio-square);
      max-inline-size: 100%;
      background-image: var(--gradient-8);
    }

    & > button {
      position: relative;
      padding: 0;
      margin: 0;
      border: none;

      &::after {
        content: '';
        inset: 0;
        display: block;
        background-color: oklch(0 0 0 / 0.4);
        position: absolute;
        background-size: var(--size-5);
        background-repeat: no-repeat;
        background-position: top var(--size-1) right var(--size-1);
        opacity: 0;
        transition: 0.2s ease;
      }

      &:hover::after {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .imageGallery {
    grid-template-columns: repeat(1, 1fr);
  }
}
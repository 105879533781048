html {
  scroll-behavior: smooth;
}

body {
  margin: 0px;
}

p {
  font-size: 1.2rem;
}

a {
  color: black;
  text-decoration: none;
}

.images_wrapper {
  display: none !important;
}

.carousel_wrapper {
  display: none;
}

main{
  text-align : center;
  font-size: 14px;
}

.main-content {
  display: grid;
}

.flex-chips {
  line-height: 2.5;
  margin: 0.5;
}

.flex-chips .MuiChip-root {
  margin-right: 0.5em;
}

.introduction {
  color: black;
  padding-top: 15%;
  padding-bottom: 15%;
  font-size: 30px;
  letter-spacing: 0.3em;
  padding-left: 10%;
  padding-right: 10%;
  text-align: left;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.5s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.container {
  padding-top: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-section {
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
}

.about-section .content {
  text-align: left;
  width: 50%;
}

.about-section .image-wrapper {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.about-section .image-wrapper img{
  width: 100%;
}

#loader-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #222222;
}

#myProgress {
  position:fixed;
  left: 47%;
  top: 50%;
  width: 100px;
  border-radius: 25px 25px;
  background-color: #ddd;
}

#myBar {
  width: 1%;
  height: 5px;
  border-radius: 25px 25px;
  background-color: #ac3b61;
}

.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
}

.about p {
  text-align: center;
  padding-top: 30%;
  padding-bottom: 30%;
}

@-webkit-keyframes animatebottom {
  from { bottom:-100px; opacity:0 } 
  to { bottom:0px; opacity:1 }
}

@keyframes animatebottom { 
  from{ bottom:-100px; opacity:0 } 
  to{ bottom:0; opacity:1 }
}

#myDiv {
  display: none;
  text-align: center;
}

.hero_wrapper {
  margin-top: 100px;
}

.hero_image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("https://photo-gallery-assets.s3.us-west-2.amazonaws.com/img/hero-beach-img.jpg");
  height: 50rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero_text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero_text h1 {
  color: white;
  font-size: 6rem !important;
  font-family: 'Cormorant', serif;
  margin-bottom: 0 !important;
}

.hero_text p {
  color: white;
}

.btn {
  margin-left: 10px;
  margin-right: 10px;
}

.btn-default {
  color: white;
  letter-spacing: 1px;
  line-height: 15px;
  border: 2px solid rgba(108, 89, 179, 0.75);
  border-radius: 40px;
  background: transparent;
  transition: all 0.3s ease 0s;
}

.btn-default:hover {
  color: black;
  background: white;
  border: 2px solid white;
}

.carousel_wrapper {
  padding-top: 5%;
  padding-bottom: 5%;
}

.carousel-caption {
  position: initial;
}

@media screen {
  @media (max-width: 990px) {
    #myProgress {
      position: fixed;
      left: 40%;
      top: 50%;
      border-radius: 25px 25px;
      background-color: #ddd;
    }

    .nav-left{
      padding-top: 20px;
      float:left;
    }

    .nav-left img {
      height: 35px
    }

    .nav-right {
      float:right;
    }

    .nav-right li {
      padding: 10px 15px;
      list-style:none;
      font-size:13px;
      color:white;
      width: 10%;
    }

    .nav-right ul {
      list-style-type: none;
      margin: 0px;
      padding: 0px;
    }

    body {
      width: 100%;
    }

    .hero_text h1 {
      color: white;
      font-size: 7rem !important;
      font-family: 'Cormorant', serif;
    }

    .introduction {
      font-size: 18px;
      letter-spacing: 0.3em;
      padding-left: 5%;
      padding-right: 5%;
    }

    .introduction img {
      width: 60%;
    }

    .about {
      padding-left: 5%;
      padding-right: 5%;
      font-size: 12px;
    }

    .skills {
      font-size: 12px;
    }

    .experience {
      font-size: 12px;
      padding-left: 5%;
      padding-right: 5%;
    }

    .content-1 li {
      width: 7%;
    }
  
    .content-2 li {
      width: 15%;
    }
    
  }
}

@media (max-width:990px) {
  .about-section .image-wrapper img {
    width: 80%;
  }

  .about-section h1 {
    text-align: left;
  }

  .about-section p {
    text-align: left;
  }

   .container {
      display: initial;
  }

  .about-section {
    display: initial;
  }

  .about-section .content {
    text-align: center;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
  }

  .about-section .image-wrapper {
    width: 100%;
  }
  
  .introduction {
    background-attachment: initial;
    padding-top: 35%;
    padding-bottom: 35%;
  }
}

@media (max-width:990px) {
  .introduction {
    padding-top: 40%;
    padding-bottom: 40%;
  }
}

@media (max-width:990px) {
  .introduction {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen {
  @media (max-width: 600px) {
    .hero_wrapper .hero_image {
      height: 30rem;
    }
    .hero_text h1 {
      color: white;
      font-size: 4rem !important;
      font-family: 'Cormorant', serif;
    }
    .navigation-bar {
      justify-content: normal;
    }

    .home-container .paragraph {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .home-container h1 {
      padding-left: 10%;
      padding-right: 10%;
    }

    .home-container p {
      padding-left: 10%;
      padding-right: 10%;
    }

    .home-container-1 img {
      height: 300px;
    }

    .home-container-2 img {
      height: 500px;
      padding-bottom: 15px;
    }

    .align-center p {
      font-size: 1rem;
    }

    .align-center a {
      font-size: 0.8rem;
    }

    .grid-parent {
      padding-left: 0px !important;
      padding-right: 15px !important;
    }

    // .footer-list-container .MuiGrid-item {
    //   padding-left: 0px !important;
    // }
  }
}
.footer {
  margin-top: 5%;
  background-color: #050f0b;
  color: white;
}

.contact {
  padding-left: 5%;
  padding-right: 5%;
}

.contact p {
  font-size: 0.65rem;
  letter-spacing: 0.3em;
}

.section {
  padding-left: 10%;
  padding-right: 10%;
  text-align: left;
}

.line {
  height: 1px;
  background-color: #8e8e8e;
  width: 100%;
  margin-bottom: 30px;
}
  
.fa {
  padding: 10px;
  width: 15px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}
  
.info li{
  list-style:none;
}
  
.info ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  display: flex;
}
  
.info ul li {
  padding: 10px;
}

.info img {
  width: 35px;
}

.domain::before {
  content: '@';
}

.arrow_section {
  display: flex;
  justify-content: center;
}

.arrow {
  background-image: url("https://static-cdn.wdh.gg/assets/arrow_down_dark.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.link {
  cursor: pointer;
  height: 20px;
  width: 50px;
  line-height: 60px;
  text-align: center;
  font-size: 70px;
  z-index: 100;
  text-decoration: none;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  animation: move_down 3s ease-in-out infinite;
}

body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
}

.about_img img {
  width: 100%;
}

.footer-list-container {
  padding: 10%;
}

.footer-list-container ul {
  list-style-type: none;
  padding-left: 0px;
  display: inline-block;
  text-align: left;
  line-height: 1.7;
}

.footer-list-container ul li {
  font-size: 1.1rem;
  font-family: 'DomaineDispNar-Regular', sans-serif;;
}

.footer-list-container a {
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  color: white;
}

.footer-list-container a:hover {
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  color: #3b71ca;
}

.footer-list-container .first {
  text-align: left;
}

.footer-list-container .first img {
  padding-bottom: 15px;
}

.footer-list-container .first p {
  font-size: 0.9rem;
}

.footer-list-container .align-center {
  text-align: center;
}

.footer-copyright {
  padding-bottom: 15px;
}

.social_links {
  padding-top: 2%;
  padding-bottom: 2%;
}

.social_links a {
  padding: 10px;
}

.social_links svg {
  font-size: 1.7rem;
  color: white;
}

.social_links svg:hover {
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  color: #3b71ca;
}

@keyframes move_down {
  0% {
      transform: translate(0, -20px);
  }
  50% {
      transform: translate(0, 0px);
  }
  100% {
      transform: translate(0, -20px);
  }
}

@media (max-width:990px) {
  .contact {
    padding-bottom: 100px;
  }

  .list_wrapper {
    padding-left: 5%;
  }
}

@media (max-width:850px) {
  .page_bottom button {
    padding: 5% 10% !important;
  }
}
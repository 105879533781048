.contact_wrapper svg {
    font-size: 1em;
    vertical-align: middle;
    margin-right: 5px;
}

.contact-form-wrapper {
    button {
        float: right;
        background-color: #050f0b;
        color: #fff;
    }

    button:hover {
        background-color: #050f0b;
        filter: brightness(150%);
    }
}

label {
    font-size: 1.2rem;
    font-family: 'DomaineDispNar-Medium', sans-serif;
}

.form-flex {
    display: flex;
    gap: 15px;
    padding-bottom: 15px;
}

.form-flex .MuiFormControl-root {
    width: 100%;
}

form {
    width: 100% !important;
}

.body-form {
    width: 100% !important;
    margin-bottom: 15px !important;
}
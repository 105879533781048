.page_header {
    width: 100%;
    height: 60vh;
    position: relative;
    z-index: 0;
}

.page_header img {
    width: 100%;
    height: 60vh;
    object-fit: fill;
}

.card_wrapper {
    display: flex;
    flex-direction: row;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.card {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    box-shadow: none;
}
  
.card .text{
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.card .text img{
    width: 15vw;
    border-radius: 50%;
    margin-bottom:10px;
}
  
.card .text h3{
    font-size: 40px;
    font-weight: 400;
}
  
.card .text p:nth-of-type(1){
    color: #d96a14;
    font-size: 15px;
    margin-top: -5px;
}
  
.card .text p:nth-of-type(2){
    margin: 10px;
    width: 90%;
    text-align: center;
}
  
.card .links{
    width: 30%;
    display: flex;
    justify-content: space-evenly;
}
  
.card .links i{
    color: #d96a14;
    font-size: 20px;
    cursor: pointer;
}
  
.card .links i:hover{
    color:#e4975b;
}
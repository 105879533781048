.services_wrapper {
  padding-top: 10%;
}

.services_section {
  display: flex;
  justify-content: space-between;
}
  
.services_section .content {
  text-align: left;
  width: 50%;
}

.works_link:hover {
  color: #3b71ca;;
  text-decoration: underline;
}

.grid_link {
  color: #000;
  font-size: 1.2rem;
  text-align: center;
  font-family: "DomaineDispNar-Regular", sans-serif;
  padding-top: 5px;
}

.grid_link:hover {
  color: #3b71ca;;
  text-decoration: underline;
}

.works_list_grid {
  padding: 0px 5%;
  // display:grid;
  // grid-template-columns: 50% 50%;
  // grid-row: auto auto;
  // grid-column-gap: 20px;
  // grid-row-gap: 5%;
}

.grid_element{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 25px;
}

.grid_element iframe {
  width: 100%;
  height: 70vh;
}

.w_img {
  width: 100%;
}

.sig_txt {
  font-family: 'Tangerine';
  font-size: 3rem;
  float: right;
}

.list_wrapper {
  height: 100%;
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 10%;
  text-align: center;
}

.list_wrapper .bgd_color {
  background-color: #bcb6b5;
  height: 100%;
  padding-right: 10%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.services_list {
  text-align: center;
  width: 50%;
  font-style: italic;
}

.services_list ul li {
  line-height: 2.5;
  font-family: 'Libre Baskerville', serif;
  color: white;
  font-size: 1.8rem;
  list-style-type: none;
}

@media (max-width:990px) {
  .services_section h1 {
    text-align: left;
  }

  .services_section p {
    text-align: left;
  }

  .container {
    display: initial;
  }

  .services_section {
    display: initial;
  }

  .services_section .content {
    text-align: center;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
  }

  .services_list {
    width: 100%;
    padding-right: 5%;
  }

  .services_list ul li {
    font-size: 1.5rem;
  }
}